<template>
  <div class="content min_box" v-wechat-title="title">
    <nav-bar :title="title" />
    <div v-if="type == 1">
      <van-tabs
        color="#35a3bd"
        v-model="navIndex"
        @change="onChange"
        swipeable
        animated
      >
        <van-tab v-for="(item, index) in navList" :title="item" :key="index">
          <div
            class="detail"
            v-if="
              articleTourList[index].detail && navList.length - 1 != navIndex
            "
            v-html="articleTourList[index].detail.impression_description"
          ></div>
          <div v-else>
            <pic-list
              :list="videoList"
              @toDetail="toVideo"
              picFlag
              :picPositionFlag="false"
              viodeImg
            ></pic-list>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    
  </div>
</template>

<script>
import navBar from "@/components/nav/nav";
import picList from "@/components/picList/picList";
import { indexfeature, impression_detail } from "@/api/http";
import { Tab, Tabs, List, Image as VanImage } from "vant";

export default {
  name: "Vue",
  data() {
    return {
      type: 1,
      navList: [],
      navIndex: 0,
      list: [],
      title: "详情页",
      region: null,
      articleTourList: [],
      videoList: [],
    };
  },

  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
    [VanImage.name]: VanImage,
    navBar,
    picList,
  },

  created() {
    this.region = JSON.parse(localStorage.getItem("region_name"));
    // 1是地区简介 2是
    this.type = this.$route.params.type;
    if (this.type == 1) {
      this.title = "地区简介";
      this.navList = [
        this.region.re_name ? "初识" + this.region.re_name : "简介",
        "城市名片",
        "历史人文",
        this.region.re_name ? this.region.re_name + "影像" : "影像",
      ];
      this.navList.forEach((v, index) => {
        this.articleTourList.push({
          cate: 9 + index,
          detail: null,
        });
      });
      this.articleTour(9);
    }
    this.navList.forEach((v) => {
      this.list.push({
        page: 1,
        flag: false,
        lists: [],
      });
    });
  },
  methods: {
    onChange(index, title) {
      if (
        this.articleTourList[index].detail != null ||
        this.videoList.length > 0
      ) {
        return;
      }
      if (this.navList.length - 1 == index) {
        indexfeature({
          region_code: this.$route.params.code,
        }).then((res) => {
          let arr = res.data.indexfeatures;
          if (arr.length > 0) {
            arr.forEach((v) => {
              if (v.features_cate == 1) {
                this.videoList.push({
                  id: v.id,
                  name: v.features_name,
                  url:
                    v.features_url + "?x-oss-process=video/snapshot,t_0,f_jpg",
                });
              }
            });
          }
        });
      }
      this.articleTour(this.articleTourList[index].cate);
    },
    //四大分类
    articleTour(e) {
      impression_detail({
        region_code: this.$route.params.code,
        impression_cate: e,
      }).then((res) => {
        this.articleTourList[this.navIndex].detail = res.data.impression_detail;
        let arr =
          this.articleTourList[this.navIndex].detail.impression_description;
        this.articleTourList[this.navIndex].detail.impression_description = arr
          ? arr.replace(/\<img/gi, '<img style="width:100%"')
          : arr;
      });
    },
    //是视频界面
    toVideo(e) {
      this.$router.push({
        name: "VideoatlasDetail",
        params: {
          type: 1,
          id: e.id,
          code: this.$route.params.code,
        },
      });
    },
  },
};
</script>
<style lang='less' scoped>
.detail {
  padding: 20px;
}
.youshi {
    display: flex;
    align-items: center;
    height: calc(100vh - 44px);
}
.box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}
.list {
  width: 49%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 12px;
  .imgs{
      width: 100%;
      height: 250px;
  }
  .txt {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
</style>